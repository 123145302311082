export enum Pathname {
    HOME = '/*',
    WORKSPACEBOOKING = 'bookings',
    MYSPACE = 'myspace',
    OFFER = 'offer',
    SETTINGS = 'settings',
    CONTACT = 'contact',
    SPACE = '/space/*',
    COMPANY = '/company/*',
    SUBSCRIPTION = '/company/',
    CREDITS = 'credits',
    USER = 'user',
    DASHBOARD = 'dashboard',
    BOOKING = 'booking',
    BILLS = 'bills',
}
