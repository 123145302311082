import React from 'react';
import * as styles from './ErrorMessage.css';
import alertIcon from '../../images/alert-line.svg';

const ErrorMessage = ({ text, width }: { text: string; width: number }): React.ReactElement => {
    return (
        <div className={styles.feedback} style={{ width: `${width}rem` }}>
            <img className={styles.alertIcon} src={alertIcon} alt="" />
            <h4 className={styles.imageWarning}>{text}</h4>
        </div>
    );
};

export default ErrorMessage;
