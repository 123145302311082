import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '@ubique-innovation/react-translations';
import CreditsIcon from '../../images/company/credits.svg';
import UserIcon from '../../images/company/user.svg';
// import DashboardIcon from '../../images/company/dashboard.svg';
import BookingIcon from '../../images/sidebar/bookings.svg';
// import BillIcon from '../../images/company/bills.svg';
import ContactDetailsIcon from '../../images/company/contact-details.svg';
import * as styles from './CompanySidebar.css';
import { Pathname } from '../../types/Pathname';

const NavLink = ({
    text,
    href,
    icon,
    onClick,
    incomplete,
}: {
    text: string;
    href: Pathname;
    icon: string;
    onClick?: React.MouseEventHandler;
    incomplete?: boolean;
}): React.ReactElement => {
    const location = useLocation();
    return (
        <>
            <li className={styles.navListItem}>
                <Link
                    className={
                        location.pathname === `/company/${href}` ? styles.navLink.active : styles.navLink.standard
                    }
                    to={href}
                    onClick={onClick}
                >
                    <img className={styles.navIcon} src={icon} alt="" />
                    <div
                        className={
                            location.pathname === `/company/${href}`
                                ? styles.navListDiv.active
                                : styles.navListDiv.standard
                        }
                    >
                        <div className={styles.btnText}>
                            {text}
                            {incomplete && <span className={styles.redDot} />}
                        </div>
                    </div>
                </Link>
            </li>
        </>
    );
};

NavLink.defaultProps = { onClick: undefined, incomplete: false };

const CompanySidebar = ({ incomplete }: { incomplete: boolean }): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <ul className={styles.sidebarUl}>
                <div className={styles.navItems}>
                    <div className={styles.headerTitle}>{t('company.nav.title.abo')}</div>
                    <NavLink text={t('company.nav.credits')} icon={CreditsIcon} href={Pathname.CREDITS} />
                    <NavLink text={t('company.nav.user')} icon={UserIcon} href={Pathname.USER} />
                    <div className={styles.headerTitle}>{t('company.nav.title.use')}</div>
                    {/* <NavLink text={t('company.nav.dashboard')} icon={DashboardIcon} href={Pathname.DASHBOARD} /> */}
                    <NavLink text={t('company.nav.booking')} icon={BookingIcon} href={Pathname.BOOKING} />{' '}
                    {/* <NavLink text={t('company.nav.bills')} icon={BillIcon} href={Pathname.BILLS} />{' '} */}
                    <div className={styles.headerTitle}>{t('company.nav.title.account')}</div>
                    <NavLink
                        text={t('company.nav.contactDetails')}
                        icon={ContactDetailsIcon}
                        href={Pathname.CONTACT}
                        incomplete={incomplete}
                    />
                </div>
            </ul>
        </>
    );
};

export default CompanySidebar;
