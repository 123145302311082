import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useEffect } from 'react';

import Arrow from '../../images/arrow.svg';
import { CompanyBooking } from '../../types/Bookings';
import PaginationSelect from './PaginationSelect';
import SortArrows from './SortArrows';
import * as styles from './Table.css';

const Table = <T extends object>({
    data,
    columns,
    title,
    sort,
    withDetail,
    setShowDetailView,
    setBookingDetail,
}: {
    data: T[];
    columns: ColumnDef<T>[];
    title?: string;
    sort: string;
    withDetail?: boolean;
    setShowDetailView?: (d: boolean) => void;
    setBookingDetail?: (d: CompanyBooking) => void;
}): React.ReactElement => {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const { t } = useTranslation();

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        enableSortingRemoval: false,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        //
        debugTable: true,
    });

    useEffect(() => {
        table.getHeaderGroups().forEach((headerGroup) =>
            headerGroup.headers.forEach((header) => {
                if (flexRender(header.column.columnDef.header, header.getContext()) === sort) {
                    header.column.toggleSorting(true);
                }
            }),
        );
    }, [sort, table]);

    return (
        <>
            <div className={styles.wrapper}>
                <>
                    <div className={styles.tableTitle}>{title}</div>
                    {table.getRowModel().rows.length < 1 ? (
                        <div>{t('no.bookings')}</div>
                    ) : (
                        <>
                            <table className={styles.table}>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id} className={styles.row}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th key={header.id} colSpan={header.colSpan} className={styles.col}>
                                                    {header.isPlaceholder ? null : (
                                                        <div className={styles.colHeader}>
                                                            <div
                                                                className={styles.headerTitle.active}
                                                                role="button"
                                                                tabIndex={0}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                                onKeyDown={header.column.getToggleSortingHandler()}
                                                            >
                                                                <div
                                                                    className={
                                                                        header.column.getIsSorted() === false
                                                                            ? styles.header.active
                                                                            : styles.header.disabled
                                                                    }
                                                                >
                                                                    {flexRender(
                                                                        header.column.columnDef.header,
                                                                        header.getContext(),
                                                                    )}
                                                                </div>
                                                                <SortArrows
                                                                    arrowOption={header.column.getIsSorted() as string}
                                                                />
                                                            </div>
                                                            {/* {header.column.getCanFilter() ? (
    <div className={styles.filter}>
        <Filter column={header.column} />
    </div>
) : null} */}
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                                <tbody>
                                    {table.getRowModel().rows.map((row) => {
                                        return (
                                            <>
                                                {withDetail ? (
                                                    <div
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() => {
                                                            if (setShowDetailView !== undefined && setBookingDetail) {
                                                                setShowDetailView(true);
                                                                setBookingDetail(row.original as CompanyBooking);
                                                            }
                                                        }}
                                                        onKeyDown={() => {
                                                            if (setShowDetailView !== undefined && setBookingDetail) {
                                                                setShowDetailView(true);
                                                                setBookingDetail(row.original as CompanyBooking);
                                                            }
                                                        }}
                                                    >
                                                        <tr key={row.id} className={styles.rowBtn}>
                                                            {row.getVisibleCells().map((cell) => {
                                                                return (
                                                                    <td key={cell.id} className={styles.col}>
                                                                        {flexRender(
                                                                            cell.column.columnDef.cell,
                                                                            cell.getContext(),
                                                                        )}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    </div>
                                                ) : (
                                                    <tr key={row.id} className={styles.row}>
                                                        {row.getVisibleCells().map((cell) => {
                                                            return (
                                                                <td key={cell.id} className={styles.col}>
                                                                    {flexRender(
                                                                        cell.column.columnDef.cell,
                                                                        cell.getContext(),
                                                                    )}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                )}
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className={styles.paginationWrapper}>
                                <div className={styles.paginationBtns}>
                                    <div className={styles.btns}>
                                        {table.getCanPreviousPage() && (
                                            <button
                                                className={
                                                    !table.getCanPreviousPage()
                                                        ? styles.paginationBtn.right
                                                        : styles.paginationBtn.left
                                                }
                                                onClick={() => table.previousPage()}
                                                disabled={!table.getCanPreviousPage()}
                                                type="button"
                                            >
                                                {!table.getCanPreviousPage() ? (
                                                    <img className={styles.rotateArrow.left} src={Arrow} alt="arrow" />
                                                ) : (
                                                    <img className={styles.rotateArrow.left} src={Arrow} alt="arrow" />
                                                )}
                                            </button>
                                        )}

                                        <span className={styles.pageNumber}>
                                            {table.getState().pagination.pageIndex + 1} / {table.getPageCount()}
                                        </span>
                                        {table.getCanNextPage() && (
                                            <button
                                                className={styles.paginationBtn.right}
                                                onClick={() => table.nextPage()}
                                                disabled={!table.getCanNextPage()}
                                                type="button"
                                            >
                                                <img className={styles.rotateArrow.right} src={Arrow} alt="arrow" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.paginationSelect}>
                                    <PaginationSelect
                                        options={[
                                            {
                                                value: 10,
                                                label: `${t('pagination.number')} 10`,
                                            },
                                            {
                                                value: 25,
                                                label: `${t('pagination.number')} 25`,
                                            },
                                            {
                                                value: 100,
                                                label: `${t('pagination.number')} 100`,
                                            },
                                        ]}
                                        value={table.getState().pagination.pageSize}
                                        onChange={(e) => {
                                            table.setPageSize(Number(e));
                                        }}
                                        border={false}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            </div>
        </>
    );
};

Table.defaultProps = {
    title: '',
    withDetail: false,
    setShowDetailView: true,
    setBookingDetail: true,
};

export default Table;
