import includes from 'lodash/includes';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from '@ubique-innovation/react-translations';
import { useAuth } from 'react-oidc-context';
import * as styles from './Header.css';

import Select from '../../components/templates/Select';
import SwitchToggle from '../../components/templates/SwitchToggle';
import usePermissions from '../../hooks/usePermissions';
import FleskLogo from '../../images/flesk-wordmark-p.svg';
import LogoutIcon from '../../images/sidebar/logout.svg';
import { Pathname } from '../../types/Pathname';
import { Locale } from '../../types/Workspace';
import { themeClass } from '../theme.css';
import ContactSupportTab from './ContactSupportTab';

const Header = (): React.ReactElement => {
    const auth = useAuth();
    const [showContact, setShowContact] = useState(false);
    const [workspaceTab, setWorkspaceTab] = useState<boolean>(false);
    const { t, lang, changeLang } = useTranslation();
    const permissions = usePermissions();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith('/company')) {
            setWorkspaceTab(false);
        } else {
            setWorkspaceTab(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        document.documentElement.setAttribute('lang', lang);
    }, [lang]);

    return (
        <div className={`${styles.app} ${themeClass}`}>
            <>
                <div className={styles.header}>
                    <div className={styles.fleskLogo}>
                        <img src={FleskLogo} alt="Flesk Logo" />
                    </div>
                    {includes(permissions, 'ADMIN') && includes(permissions, 'ABO_MANAGER') ? (
                        <>
                            <div className={styles.navigation}>
                                <SwitchToggle
                                    id="abo-workspace"
                                    checked={workspaceTab}
                                    onChange={() => {
                                        if (workspaceTab) {
                                            setWorkspaceTab(false);
                                            navigate(`${Pathname.SUBSCRIPTION}${Pathname.CREDITS}`);
                                        } else {
                                            setWorkspaceTab(true);
                                            navigate(Pathname.SPACE);
                                        }
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={styles.navigation} />
                    )}
                    <div
                        className={styles.contactLink}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setShowContact(true)}
                        onClick={() => setShowContact(true)}
                    >
                        <div className={styles.navListDiv}>{t('navigation.contactSupport')}</div>
                    </div>
                    <div className={styles.langDropdown}>
                        <Select
                            options={Object.values(Locale).map((locale) => ({
                                value: locale,
                                label: locale.toUpperCase(),
                            }))}
                            value={lang}
                            onChange={(v: Locale) => {
                                changeLang(v);
                                localStorage.setItem('lang', v);
                                document.documentElement.setAttribute('lang', v);
                            }}
                            width="4rem"
                            border={false}
                        />
                    </div>

                    <div className={styles.logoutDiv}>
                        <li className={styles.logout}>
                            <button className={styles.logoutLink} type="button" onClick={() => auth.signoutRedirect()}>
                                <img className={styles.navIcon} src={LogoutIcon} alt="" />
                                <div className={styles.navListDiv}>{t('navigation.logout')}</div>
                            </button>
                        </li>
                    </div>
                    {showContact && <ContactSupportTab setShowContact={setShowContact} />}
                </div>
            </>
        </div>
    );
};

export default Header;
