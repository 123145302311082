import React from 'react';

import { useTranslation } from '@ubique-innovation/react-translations';
import { ColumnDef } from '@tanstack/react-table';

import { Booking, BookingState } from '../../../types/Bookings';
import * as styles from './BookingsOverview.css';
import { ProductType, TableType } from '../../../types/Workspace';
import Table from '../../templates/Table';
import { sortTableBookingsColumn } from '../../../utils/bookings';

const BookingsOverview = ({
    bookings,
    tableType,
}: {
    bookings: Booking[];
    tableType: TableType;
}): React.ReactElement => {
    const { t } = useTranslation();

    const data: Booking[] = bookings;

    const productString = {
        [ProductType.FLESK_DESK]: t('flesk.product.fleskDesk.name'),
        [ProductType.FLESK_DESK_DROP_IN]: t('flesk.product.fleskDeskDropIn.name'),
        [ProductType.FLESK_POD]: t('flesk.product.fleskPod.name'),
        [ProductType.FLESK_ROOM_SMALL]: t('flesk.product.fleskRoomSmall.name'),
        [ProductType.FLESK_ROOM_MEDIUM]: t('flesk.product.fleskRoomMedium.name'),
        [ProductType.FLESK_ROOM_LARGE]: t('flesk.product.fleskRoomLarge.name'),
    };

    const stateString = {
        [BookingState.REQUESTED]: t('bookings.status.requested'),
        [BookingState.APPROVED]: t('bookings.status.approved'),
        [BookingState.FINISHED]: t('bookings.status.finished'),
        [BookingState.CHECKED_IN]: t('bookings.status.checked_in'),
        [BookingState.DECLINED]: t('bookings.status.declined'),
        [BookingState.CANCELLED_REQUEST]: t('bookings.status.cancelled_request'),
        [BookingState.CANCELLED_BOOKING]: t('bookings.status.cancelled_booking'),
        [BookingState.ONBOARDED]: t('bookings.status.onboarded'),
    };

    const formatDate = (date: Date): string => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const formatMultiDate = (start: number, end: number): string => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const startMonth = startDate.getMonth() + 1;
        const endMonth = endDate.getMonth() + 1;
        const year = endDate.getFullYear();
        let fullDate;
        if (startMonth === endMonth) {
            fullDate = `${startDay}.–${endDay}.${startMonth}.${year}`;
        } else {
            fullDate = `${startDay}.${startMonth}.–${endDay}.${endMonth}.${year}`;
        }
        return fullDate;
    };

    const getDate = (start: number, end: number, bookingsOption: string): string => {
        const startDateFormatted = formatDate(new Date(start));
        if (bookingsOption === 'MULTIDAY') {
            // endDate is midnight at the start of the next day
            return formatMultiDate(start, end);
        }
        return startDateFormatted;
    };

    const formatTime = (date: Date): string => {
        const hour = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${hour}:${minutes}`;
    };

    const getTime = (start: number, end: number, bookingsOption: string): string => {
        if (bookingsOption === null) {
            const startTimeFormatted = formatTime(new Date(start));
            if (end === null) {
                return startTimeFormatted;
            }
            const endTimeFormatted = formatTime(new Date(end));
            return `${startTimeFormatted}–${endTimeFormatted}`;
        }
        if (bookingsOption === 'DAYPASS') {
            return t('bookings.option.daypass');
        }
        if (bookingsOption === 'MORNING_PASS') {
            return t('bookings.option.morning_pass');
        }
        if (bookingsOption === 'AFTERNOON_PASS') {
            return t('bookings.option.afternoon_pass');
        }
        return t('bookings.option.multipass');
    };

    const columns = React.useMemo<ColumnDef<Booking>[]>(
        () => [
            {
                header: t('bookings.category.name'),
                accessorKey: 'name',
                cell: (info) => info.getValue(),
            },
            {
                header: t('bookings.category.date'),
                sortingFn: sortTableBookingsColumn,
                accessorFn: (info) => `  ${getDate(info.startAt, info.endAt, info.bookingOption)}
                ${getTime(info.startAt, info.endAt, info.bookingOption)}`,
            },
            {
                header: t('bookings.category.product'),
                accessorKey: 'productType',
                cell: (info) => productString[info.getValue() as ProductType],
            },

            {
                header: t('bookings.category.state'),
                accessorKey: 'bookingState',
                cell: (info) => stateString[info.getValue() as BookingState],
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div className={styles.wrapper}>
            {tableType === 'current' && (
                <>
                    <Table
                        title={t('bookings.open')}
                        sort={t('bookings.category.date')}
                        {...{
                            data,
                            columns,
                        }}
                    />
                </>
            )}{' '}
            {tableType === 'past' && (
                <>
                    <Table
                        title={t('bookings.closed')}
                        sort={t('bookings.category.date')}
                        {...{
                            data,
                            columns,
                        }}
                    />
                </>
            )}
            {tableType === 'cancelled' && (
                <>
                    <Table
                        title={t('bookings.cancelled')}
                        sort={t('bookings.category.date')}
                        {...{
                            data,
                            columns,
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default BookingsOverview;
