import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import some from 'lodash/some';

import { useAuth } from 'react-oidc-context';
import { useTranslation } from '@ubique-innovation/react-translations';
import { useRecoilState } from 'recoil';

import * as styles from './Company.css';
import CompanySidebar from './CompanySidebar';
import { Pathname } from '../../types/Pathname';
import { themeClass } from '../theme.css';
import { companyIdState } from '../../types/CompanyState';
import CompanyBookings from './CompanyBookings';
import useCompanyIdsFromToken from '../../hooks/useCompanyIdsFromToken';
import useHistory from '../../hooks/useHistory';
import { Contact, InvoiceRecipient } from '../../types/Workspace';
import CompanyAccount from './CompanyAccount';
import LoadingIndicator from '../../components/LoadingIndicator';
import Credits from './Credits';
import User from './User';

const API = process.env.REACT_APP_API_URL || '';

const Company = (): React.ReactElement => {
    const auth = useAuth();
    const { t } = useTranslation();
    const companyIdsFromToken = useCompanyIdsFromToken();
    const [companyId, setCompanyId] = useRecoilState(companyIdState);
    const loaded = useHistory();

    const [invoiceRecipient, setInvoiceRecipient] = useState<InvoiceRecipient>();
    const [contact, setContact] = useState<Contact>();

    const [loading, setLoading] = useState(true);
    const [savingError, setSavingError] = useState<string>();

    const [incomplete, setIncomplete] = useState(false);

    useEffect(() => {
        setLoading(false);
        if (companyId == null) {
            // TODO handle this
            // console.error('this user has no company');
            return;
        }
        fetch(`${API}/v1/admin/companies/${companyId}/invoicerecipient`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => (response.ok ? response.json() : Promise.reject()))
            .then((data) => {
                setInvoiceRecipient(data);
                setLoading(false);
            });
    }, [auth.user?.access_token, companyId, auth, t]);

    useEffect(() => {
        setLoading(false);
        if (companyId == null) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/companies/${companyId}/contact`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => (response.ok ? response.json() : Promise.reject()))
            .then((data) => {
                setContact(data);
                setLoading(false);
            });
    }, [auth.user?.access_token, companyId, incomplete, t]);

    const onInvoiceRecipientSave = useCallback(
        (w: InvoiceRecipient) => {
            setLoading(true);
            const s = { ...w };
            if (s.mail === '') {
                s.mail = null;
            }
            if (companyId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            fetch(`${API}/v1/admin/companies/${companyId}/invoicerecipient`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(s),
            })
                .then((response) => (response.ok ? response.json() : Promise.reject()))
                .then((data) => {
                    setInvoiceRecipient(data);
                    setLoading(false);
                    setSavingError(undefined);
                    return data;
                })
                .catch(() => {
                    setSavingError(t('messages.error.errorWhileSaving'));
                    setLoading(false);
                    setSavingError(t('messages.error.errorWhileSaving'));
                });
        },
        [companyId, auth.user?.access_token, t],
    );

    const onContactSave = useCallback(
        (w: Contact) => {
            setLoading(true);
            const s = { ...w };
            if (s.mail === '') {
                s.mail = null;
            }
            if (companyId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            fetch(`${API}/v1/admin/companies/${companyId}/contact`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(s),
            })
                .then((response) => (response.ok ? response.json() : Promise.reject()))
                .then((data) => {
                    setContact(data);
                    setLoading(false);
                    setSavingError(undefined);
                    return data;
                })
                .catch(() => {
                    setSavingError(t('messages.error.errorWhileSaving'));
                    setLoading(false);
                });
        },
        [companyId, auth.user?.access_token, t],
    );

    useEffect(() => {
        if (loaded) {
            if (companyIdsFromToken && (companyId == null || !companyIdsFromToken.includes(companyId))) {
                setCompanyId(companyIdsFromToken ? companyIdsFromToken[0] : 0);
            }
        }
    });

    const isComplete = (): void => {
        if (invoiceRecipient && contact) {
            const empty = some(
                [...Object.values(invoiceRecipient), ...Object.values(contact)],
                (e) => e === '' || e === null,
            );
            if (empty) {
                setIncomplete(true);
            } else {
                setIncomplete(false);
            }
        }
    };

    useEffect(() => {
        isComplete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceRecipient, contact]);

    return (
        <div className={`${styles.app} ${themeClass}`}>
            {/* {permissions == null || includes(permissions, 'ADMIN') ? ( */}
            <>
                <div className={styles.sidebar}>
                    <CompanySidebar incomplete={incomplete} />
                </div>
                <div className={styles.content}>
                    <Routes>
                        <Route path={Pathname.CREDITS} element={<Credits companyId={companyId} />} />
                        <Route path={Pathname.USER} element={<User companyId={companyId} />} />
                        {/* <Route path={Pathname.DASHBOARD} element={<Dashboard />} /> */}
                        <Route path={Pathname.BOOKING} element={<CompanyBookings companyId={companyId} />} />
                        {/* <Route path={Pathname.BILLS} element={<Bills />} /> */}
                        <Route
                            path={Pathname.CONTACT}
                            element={
                                loading || contact == null || invoiceRecipient == null ? (
                                    <LoadingIndicator />
                                ) : (
                                    <CompanyAccount
                                        invoiceRecipient={invoiceRecipient}
                                        contact={contact}
                                        onInvoiceRecipientSave={onInvoiceRecipientSave}
                                        onContactSave={onContactSave}
                                        savingError={savingError}
                                        incomplete={incomplete}
                                    />
                                )
                            }
                        />
                        <Route path={Pathname.HOME} element={<Navigate to={Pathname.CREDITS} replace />} />
                    </Routes>
                </div>
            </>
        </div>
    );
};

export default Company;
