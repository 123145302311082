import { useTranslation } from '@ubique-innovation/react-translations';
import React from 'react';
import InfoIcon from '../../../images/info-icon.svg';
import { AboCredits } from '../../../types/Credits';
import * as styles from './CreditsTab.css';

const CreditsTab = ({ credits }: { credits: AboCredits }): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputForm}>
                <div className={styles.header}>
                    <div className={styles.text({ color: 'blueDark' })}>{t('credits.title')}</div>
                    {/* <div className={styles.text({ size: 'm', weight: 'bold' })}>{getDate()}</div> */}
                </div>
                <div className={styles.creditsOverview}>
                    <div className={styles.whiteWrapper}>
                        <div className={styles.headerWrapper}>
                            <div
                                className={styles.text({ size: 'xs', weight: 'bold', letterSpacing: 1 })}
                                style={{ paddingBottom: '1.5rem' }}
                            >
                                {t('credits.available').toUpperCase()}
                            </div>
                            <div
                                className={styles.text({
                                    color: 'lightBlue',
                                    size: 'l',
                                    weight: 'bold',
                                    display: 'flex',
                                    alignItems: 'baseLine',
                                })}
                            >
                                {credits.availableCredits}
                                <p className={styles.text({ size: 's', weight: 'normal' })}>
                                    &nbsp;{t('booking.credits')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        className={styles.btn}
                        type="button"
                        data-tally-open="mJzM0d"
                        data-tally-layout="modal"
                        data-tally-width="640"
                    >
                        {t('buy.credits.btn')}
                    </button>
                </div>
                <div className={styles.contentSection}>
                    <div className={styles.headerWrapper}>
                        <div className={styles.text({ size: 'xs', weight: 'bold', letterSpacing: 1 })}>
                            {t('credits.abonnement').toUpperCase()}
                        </div>
                    </div>
                    <div className={styles.aboSectionHeader}>
                        <div className={styles.text({ color: 'blueDark', size: 'l', weight: 'bold' })}>
                            {credits.aboName}
                        </div>
                    </div>
                </div>
                <div className={styles.infoBox}>
                    <div className={styles.text({ weight: 'bold' })}>
                        <img className={styles.infoIcon} src={InfoIcon} alt="info-icon" />
                        {t('credits.info.title')}
                    </div>
                    <div>
                        {`${t('credits.info.text')} `}
                        <a className={styles.link} href={`mailto: ${t('credits.info.mail')}`}>
                            {t('credits.info.mail')}
                        </a>
                        .
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditsTab;
