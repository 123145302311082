import React, { useEffect, useState } from 'react';
// import { useTranslation } from '@ubique-innovation/react-translations';
import { useAuth } from 'react-oidc-context';

import LoadingIndicator from '../../components/LoadingIndicator';

import * as styles from './Credits.css';
import CreditsTab from '../../components/company/credits/CreditsTab';
import { AboCredits } from '../../types/Credits';

const API = process.env.REACT_APP_API_URL || '';

const Credits = ({ companyId }: { companyId: number | undefined }): React.ReactElement => {
    const [credits, setCredits] = useState<AboCredits>();
    const [loading, setLoading] = useState(false);
    // const { t } = useTranslation();

    const auth = useAuth();
    // const [savingError, setSavingError] = useState<string>();

    useEffect(() => {
        setLoading(false);
        if (companyId == null) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/companies/${companyId}/abo`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => (response.ok ? response.json() : Promise.reject()))
            .then(
                (data) => {
                    setCredits(data);
                    setLoading(false);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [auth.user?.access_token, companyId]);

    return (
        <>
            <div className={styles.formDiv}>
                {loading || credits == null ? <LoadingIndicator /> : <CreditsTab credits={credits} />}
            </div>
        </>
    );
};

export default Credits;
