import React from 'react';
import camelCase from 'lodash/camelCase';
import { useTranslation } from '@ubique-innovation/react-translations';

import * as styles from './SingleProduct.css';
import FleskDeskIcon from '../../images/fleskOptions/flesk-desk.svg';
import FleskDeskDropInIcon from '../../images/fleskOptions/flesk-desk-drop-in.svg';
import FleskPodIcon from '../../images/fleskOptions/flesk-pod.svg';
import FleskRoomSmall from '../../images/fleskOptions/flesk-room-small.svg';
import FleskRoomMedium from '../../images/fleskOptions/flesk-room-medium.svg';
import FleskRoomLarge from '../../images/fleskOptions/flesk-room-large.svg';
import { ProductType } from '../../types/Workspace';
import PublishToggle from './PublishToggle';

const SingleProduct = ({
    type,
    checked,
    onChange,
    price,
    priceChange,
    dropInChange,
    dropInChecked,
    dailyTariff,
    dailyPrice,
    dailyPriceChange,
    deskHalfDayPassesSupported,
    deskHalfDayPassesSupportedChange,
}: {
    type: ProductType;
    checked: boolean;
    onChange: React.ChangeEventHandler;
    price: number | string;
    priceChange: React.ChangeEventHandler;
    dropInChange?: React.ChangeEventHandler;
    dropInChecked?: boolean;
    dailyTariff?: boolean;
    dailyPrice?: number | string;
    dailyPriceChange?: React.ChangeEventHandler;
    deskHalfDayPassesSupported?: boolean;
    deskHalfDayPassesSupportedChange?: React.ChangeEventHandler;
}): React.ReactElement => {
    const { t } = useTranslation();
    const getIcon = (icontype: string): string => {
        switch (icontype) {
            case ProductType.FLESK_DESK:
                return FleskDeskIcon;
            case ProductType.FLESK_DESK_DROP_IN:
                return FleskDeskDropInIcon;
            case ProductType.FLESK_POD:
                return FleskPodIcon;
            case ProductType.FLESK_ROOM_SMALL:
                return FleskRoomSmall;
            case ProductType.FLESK_ROOM_MEDIUM:
                return FleskRoomMedium;
            case ProductType.FLESK_ROOM_LARGE:
                return FleskRoomLarge;
            default:
                return '';
        }
    };

    const getClass = (isInput: boolean): string => {
        if (isInput) {
            if (checked) {
                if (price === 0 || price === '') {
                    return styles.priceInput.invalid;
                }
                return styles.priceInput.standard;
            }
            return styles.priceInput.disabled;
        }
        if (checked) {
            if (price === 0 || price === '') {
                return styles.priceLabel.invalid;
            }
            return styles.priceLabel.standard;
        }
        return styles.priceLabel.disabled;
    };

    const getDailyClass = (isInput: boolean): string => {
        if (isInput) {
            if (checked) {
                if (dailyPrice === 0 || dailyPrice === '') {
                    return styles.priceInput.invalid;
                }
                return styles.priceInput.standard;
            }
            return styles.priceInput.disabled;
        }
        if (checked) {
            if (dailyPrice === 0 || dailyPrice === '') {
                return styles.priceLabel.invalid;
            }
            return styles.priceLabel.standard;
        }
        return styles.priceLabel.disabled;
    };

    const getDropInClass = (isInput: boolean): string => {
        if (isInput) {
            if (checked && dropInChecked) {
                if (price === 0 || price === '') {
                    return styles.priceInput.invalid;
                }
                return styles.priceInput.standard;
            }
            if (checked && !dropInChecked) {
                return styles.priceInput.disabled;
            }
            return styles.priceInput.disabled;
        }
        if (checked && dropInChecked) {
            if (price === 0 || price === '') {
                return styles.priceLabel.invalid;
            }
            return styles.priceLabel.standard;
        }
        if (checked && !dropInChecked) {
            return styles.priceLabel.disabled;
        }
        return styles.priceLabel.disabled;
    };

    return (
        <>
            <label htmlFor={type} className={styles.checkbox}>
                <input
                    id={type}
                    className={styles.checkboxInput}
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                />
                <div className={styles.toggle}>
                    <div className={styles.text}>
                        <div>
                            <div className={styles.optionTitle}>
                                <input
                                    id={type}
                                    className={styles.checkboxInput2}
                                    type="checkbox"
                                    onChange={onChange}
                                    checked={checked}
                                />
                                <span className={styles.smallCheckbox} />
                                <span className={styles.productTitle}>
                                    {t(`flesk.product.${camelCase(type)}.name`)}
                                </span>
                            </div>
                            <span className={styles.productTeaser}>
                                <ul>
                                    {t(`flesk.product.${camelCase(type)}.description`)
                                        .split('\n')
                                        .map((line) => (
                                            <li key={line}>{line.replace(/^-/g, '').trim()}</li>
                                        ))}
                                </ul>
                            </span>
                        </div>
                        <div>
                            <span>
                                <img src={getIcon(type)} alt="" />
                            </span>
                        </div>
                    </div>

                    {dailyTariff && (
                        <div className={styles.priceDaily}>
                            <>
                                <>
                                    {type === ProductType.FLESK_DESK && checked && (
                                        <div>
                                            <div className={styles.placeholder} />
                                            <PublishToggle
                                                label={t('myspace.products.drop-in')}
                                                checked={dropInChecked}
                                                onChange={dropInChange}
                                                disabled={false}
                                            />
                                            <PublishToggle
                                                id="halfDay"
                                                label={t('myspace.halfDay.title')}
                                                desc={t('myspace.halfDay.desc')}
                                                checked={deskHalfDayPassesSupported}
                                                onChange={deskHalfDayPassesSupportedChange}
                                                disabled={false}
                                            />
                                        </div>
                                    )}
                                    {type === ProductType.FLESK_DESK && !checked && (
                                        <div>
                                            <div className={styles.placeholder} />
                                            <PublishToggle
                                                label={t('myspace.products.drop-in')}
                                                checked={dropInChecked}
                                                onChange={dropInChange}
                                                disabled
                                            />
                                            <PublishToggle
                                                id="halfDay"
                                                label={t('myspace.halfDay.title')}
                                                desc={t('myspace.halfDay.desc')}
                                                checked={deskHalfDayPassesSupported}
                                                onChange={deskHalfDayPassesSupportedChange}
                                                disabled
                                            />
                                        </div>
                                    )}
                                </>
                                <div className={styles.priceDiv}>
                                    <label className={getDailyClass(false)} htmlFor="input">
                                        {t('myspace.products.dailyTariff')}
                                        <input
                                            className={getDailyClass(true)}
                                            type="text"
                                            value={dailyPrice}
                                            onChange={checked ? dailyPriceChange : () => null}
                                        />
                                        <p className={styles.francs}>CHF</p>
                                    </label>
                                    <label className={getDropInClass(false)} htmlFor="input">
                                        {t('myspace.products.hourTariff')}
                                        <input
                                            className={getDropInClass(true)}
                                            type="text"
                                            value={price}
                                            onChange={dropInChecked ? priceChange : () => null}
                                        />
                                        <p className={styles.francs}>CHF</p>
                                    </label>
                                    <div className={styles.mwstText}>{t('product.mwst')}</div>
                                </div>
                            </>
                        </div>
                    )}

                    {!dailyTariff && (
                        <div className={styles.price}>
                            <div className={styles.priceDiv}>
                                <label className={getClass(false)} htmlFor="input">
                                    {t('myspace.products.hourTariff')}
                                    <input
                                        className={getClass(true)}
                                        type="text"
                                        value={price}
                                        onChange={checked ? priceChange : () => null}
                                    />
                                    <p className={styles.francs}>CHF</p>
                                </label>
                                <div className={styles.mwstText}>{t('product.mwst')}</div>
                            </div>
                        </div>
                    )}
                </div>
            </label>
        </>
    );
};

SingleProduct.defaultProps = {
    dropInChecked: false,
    dropInChange: '',
    dailyTariff: false,
    dailyPrice: '',
    dailyPriceChange: () => null,
    deskHalfDayPassesSupported: false,
    deskHalfDayPassesSupportedChange: () => null,
};

export default SingleProduct;
