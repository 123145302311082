import { Row } from '@tanstack/react-table';
import { Booking, CompanyBooking } from '../types/Bookings';

// order in which booking options should be sorted
const bookingOptionOrder = ['DAYPASS', 'MORNING_PASS', 'AFTERNOON_PASS'];

/**
 * Sorting function to sort bookings by date, considering booking options as well.
 * @param a First booking to compare
 * @param b Second booking to compare
 * @returns -1 if a should be before b, 1 if b should be before a, 0 if they are equal
 */
export const sortTableBookingsColumn = (
    rowA: Row<Booking> | Row<CompanyBooking>,
    rowB: Row<Booking> | Row<CompanyBooking>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnId: string,
): number => {
    const bookingA = rowA.original;
    const bookingB = rowB.original;
    const aStartDate = new Date(bookingA.startAt);
    const bStartDate = new Date(bookingB.startAt);

    if (
        aStartDate.getFullYear() !== bStartDate.getFullYear() &&
        aStartDate.getMonth() !== bStartDate.getMonth() &&
        aStartDate.getDate() !== bStartDate.getDate()
    ) {
        // If the bookings start on different days, sort by start date
        return aStartDate.getTime() - bStartDate.getTime();
    }

    if (bookingA.bookingOption === null && bookingB.bookingOption === null) {
        // both bookings start at the same time and have no booking option, sort by start time
        return aStartDate.getTime() - bStartDate.getTime();
    }
    if (bookingA.bookingOption === null) {
        // a has no booking option, b has one, b should be before a
        return 1;
    }
    if (bookingB.bookingOption === null) {
        // b has no booking option, a has one, a should be before b
        return -1;
    }

    // both bookings have a booking option, sort by booking option
    const aBookingOptionIndex = bookingOptionOrder.indexOf(bookingA.bookingOption);
    const bBookingOptionIndex = bookingOptionOrder.indexOf(bookingB.bookingOption);
    return aBookingOptionIndex - bBookingOptionIndex;
};
