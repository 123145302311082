import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from '@ubique-innovation/react-translations';
import * as styles from './Space.css';
import { Pathname } from '../../types/Pathname';
import { themeClass } from '../theme.css';
import { workspaceIdState } from '../../types/WorkspaceState';
import useWorkspaceIdsFromToken from '../../hooks/useWorkspaceIdsFromToken';
import Bookings from './Bookings';
import Sidebar from './Sidebar';
import Resources from './Resources';
import Infos from './Infos';
import WorkspacePreview from '../../components/workspace/WorkspacePreview';
import { Beneficiary, BookingsInfo, Contact, Workspaces } from '../../types/Workspace';
import Account from './Account';
import LoadingIndicator from '../../components/LoadingIndicator';
import useHistory from '../../hooks/useHistory';

const Space = (): React.ReactElement => {
    const API = process.env.REACT_APP_API_URL || '';
    const [dirtyForm, setDirtyForm] = useState(false);
    const auth = useAuth();
    const { t } = useTranslation();
    const workspaceIdsFromToken = useWorkspaceIdsFromToken();
    const [workspaceId, setWorkspaceId] = useRecoilState(workspaceIdState);
    const [workspaces, setWorkspaces] = useState<Workspaces[]>();
    const loaded = useHistory();

    const [beneficiary, setBeneficiary] = useState<Beneficiary>();
    const [contact, setContact] = useState<Contact>();
    const [bookingInfo, setBookingInfo] = useState<BookingsInfo>();

    const [loading, setLoading] = useState(true);
    const [savingError, setSavingError] = useState<string>();

    const [showWorkspace, setShowWorkspace] = useState<boolean>();

    const [incomplete, setIncomplete] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (workspaces !== undefined) {
            if (workspaces?.length === 1 && loaded) {
                navigate(Pathname.WORKSPACEBOOKING);
                setWorkspaceId(workspaceIdsFromToken ? workspaceIdsFromToken[0] : 0);
            } else if (workspaces.length !== 1 && workspaceId === undefined) {
                setShowWorkspace(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaces, setWorkspaceId, workspaceId, setShowWorkspace, showWorkspace]);

    useEffect(() => {
        if (workspaceId !== undefined) {
            Promise.all([
                fetch(`${API}/v1/admin/workspaces/${workspaceId}/beneficiary`, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                }),
                fetch(`${API}/v1/admin/workspaces/${workspaceId}/contact`, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                }),
                fetch(`${API}/v1/admin/workspaces/${workspaceId}/bookingmail`, {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                }),
            ])
                .then(([resBeneficiary, resContact, resBooking]) =>
                    Promise.all([resBeneficiary.json(), resContact.json(), resBooking.json()]),
                )
                .then(([dataBeneficiary, dataContact, dataBooking]) => {
                    setBeneficiary(dataBeneficiary);
                    setContact(dataContact);
                    setBookingInfo(dataBooking);
                    setLoading(false);
                });
        }
    }, [API, auth.user?.access_token, workspaceId]);

    useEffect(() => {
        setLoading(false);
        fetch(`${API}/v1/admin/workspaces`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403 || response.status === 401) {
                    auth.signoutRedirect();
                }
                return Promise.reject();
            })
            .then(
                (data) => {
                    setWorkspaces(data.workspaces);
                    setLoading(false);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [auth.user?.access_token, auth, API, workspaceId]);

    const onBeneficiarySave = useCallback(
        (w: Beneficiary) => {
            const s = { ...w };
            if (s.beneficiaryMail === '') {
                s.beneficiaryMail = null;
            }
            setLoading(true);
            if (workspaceId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            fetch(`${API}/v1/admin/workspaces/${workspaceId}/beneficiary`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(s),
            })
                .then((response) => (response.ok ? response.json() : Promise.reject()))
                .then((data) => {
                    setBeneficiary(data);
                    setLoading(false);
                    setSavingError(undefined);
                    return data;
                })
                .catch(() => {
                    setSavingError(t('messages.error.errorWhileSaving'));
                    setLoading(false);
                });
        },
        [workspaceId, auth.user?.access_token, t, API],
    );

    const onContactSave = useCallback(
        (w: Contact) => {
            setLoading(true);
            const s = { ...w };
            if (s.mail === '') {
                s.mail = null;
            }
            if (workspaceId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            fetch(`${API}/v1/admin/workspaces/${workspaceId}/contact`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(s),
            })
                .then((response) => (response.ok ? response.json() : Promise.reject()))
                .then((data) => {
                    setContact(data);
                    setLoading(false);
                    setSavingError(undefined);
                    return data;
                })
                .catch(() => {
                    setSavingError(t('messages.error.errorWhileSaving'));
                    setLoading(false);
                });
        },
        [workspaceId, auth.user?.access_token, t, API],
    );

    const onBookingSave = useCallback(
        (w: BookingsInfo) => {
            setLoading(true);
            if (workspaceId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            fetch(`${API}/v1/admin/workspaces/${workspaceId}/bookingmail`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
                body: JSON.stringify(w),
            })
                .then((response) => (response.ok ? response.json() : Promise.reject()))
                .then((data) => {
                    setBookingInfo(data);
                    setLoading(false);
                    setSavingError(undefined);
                    return data;
                })
                .catch(() => {
                    setSavingError(t('messages.error.errorWhileSaving'));
                    setLoading(false);
                });
        },
        [workspaceId, auth.user?.access_token, t, API],
    );

    const isComplete = (): void => {
        let empty = false;
        if (beneficiary && contact) {
            if (
                (beneficiary.mwstNumber === null || beneficiary.mwstNumber === '') &&
                beneficiary.mwstPflichtig === false
            ) {
                empty = false;
            } else if (
                (beneficiary.mwstNumber === null || beneficiary.mwstNumber === '') &&
                beneficiary.mwstPflichtig === true
            ) {
                empty = true;
            }
            Object.entries(beneficiary).forEach(([key, value]) => {
                if (key !== 'mwstNumber') {
                    if (value === '' || value === null) {
                        empty = true;
                    }
                }
            });

            Object.values(contact).forEach((value) => {
                if (value === null || value === '') {
                    empty = true;
                }
            });

            setIncomplete(empty);
        }
    };

    useEffect(() => {
        isComplete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiary, contact, bookingInfo]);

    return (
        <>
            <div className={`${styles.app} ${themeClass}`}>
                {showWorkspace === true || workspaceId === undefined ? (
                    <WorkspacePreview
                        workspace={workspaces !== undefined ? workspaces : []}
                        workspaceIds={workspaceIdsFromToken !== undefined ? workspaceIdsFromToken : []}
                        setWorkspaceId={setWorkspaceId}
                        workspaceId={workspaceId}
                        setShowWorkspace={setShowWorkspace}
                    />
                ) : (
                    <>
                        <div className={styles.sidebar}>
                            {workspaces !== undefined && (
                                <Sidebar
                                    workspaces={workspaces}
                                    dirtyForm={dirtyForm}
                                    setDirtyForm={setDirtyForm}
                                    workspaceId={workspaceId}
                                    incomplete={incomplete}
                                    setShowWorkspace={setShowWorkspace}
                                />
                            )}
                        </div>
                        <div className={styles.content}>
                            <Routes>
                                <Route
                                    path={Pathname.WORKSPACEBOOKING}
                                    element={<Bookings workspaceId={workspaceId} />}
                                />
                                <Route
                                    path={Pathname.MYSPACE}
                                    element={<Infos setDirty={setDirtyForm} workspaceId={workspaceId} />}
                                />
                                <Route
                                    path={Pathname.OFFER}
                                    element={<Resources setDirty={setDirtyForm} workspaceId={workspaceId} />}
                                />
                                <Route
                                    path={Pathname.SETTINGS}
                                    element={
                                        loading || beneficiary == null || contact == null || bookingInfo == null ? (
                                            <LoadingIndicator />
                                        ) : (
                                            <Account
                                                beneficiary={beneficiary}
                                                contact={contact}
                                                bookingInfo={bookingInfo}
                                                onBeneficiarySave={onBeneficiarySave}
                                                onContactSave={onContactSave}
                                                onBookingSave={onBookingSave}
                                                savingError={savingError}
                                                incomplete={incomplete}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path={Pathname.HOME}
                                    element={<Navigate to={Pathname.WORKSPACEBOOKING} replace />}
                                />
                            </Routes>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Space;
