import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams, useLocation } from 'react-router-dom';
import { urlParamsState } from '../types/UrlState';
import { workspaceIdState } from '../types/WorkspaceState';
import { companyIdState } from '../types/CompanyState';

enum SearchParam {
    WORKSPACE_ID = 'workspaceId',
    COMPANY_ID = 'companyId',
}

const useHistory = (): boolean => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [urlParsed, setUrlParsed] = useState(false);
    const urlParams = useRecoilValue(urlParamsState);
    const setWorkspaceId = useSetRecoilState(workspaceIdState);
    const setCompanyid = useSetRecoilState(companyIdState);
    // parse searchParams on site load
    useEffect(() => {
        searchParams.forEach((value, key) => {
            if (key === SearchParam.WORKSPACE_ID) {
                setWorkspaceId(parseInt(value, 10));
                setUrlParsed(true);
            } else if (key === SearchParam.COMPANY_ID) {
                setCompanyid(parseInt(value, 10));
                setUrlParsed(true);
            } else {
                setUrlParsed(true);
            }
        });
        setUrlParsed(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const params = new URLSearchParams();

        if (urlParams.workspaceId) {
            params.append(SearchParam.WORKSPACE_ID, urlParams.workspaceId.toString());
        }
        if (urlParams.companyId) {
            params.append(SearchParam.COMPANY_ID, urlParams.companyId.toString());
        }

        const { hash } = location;
        setSearchParams(params, { replace: true });
        window.location.hash = hash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, urlParams, setSearchParams]);
    return urlParsed;
};

export default useHistory;
