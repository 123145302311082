import React, { useEffect, useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import { useAuth } from 'react-oidc-context';
import filter from 'lodash/filter';

import * as styles from './CompanyBookings.css';
import LoadingIndicator from '../../components/LoadingIndicator';
import { CompanyBooking } from '../../types/Bookings';
import { ProductType, TableType } from '../../types/Workspace';
import CompanyBookingsTab from '../../components/company/companyBookings/CompanyBookingsTab';

const CompanyBookings = ({ companyId }: { companyId: number | undefined }): React.ReactElement => {
    const [bookings, setBookings] = useState<CompanyBooking[]>([]);
    const [loading, setLoading] = useState(true);
    const API = process.env.REACT_APP_API_URL || '';
    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (companyId == null) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/companies/${companyId}/bookings`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then(
                (data) => {
                    setBookings(data.bookings);
                    setLoading(false);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [API, auth, auth.user?.access_token, companyId]);

    const getFilteredBookings = (table: TableType): CompanyBooking[] => {
        const now = new Date();
        const startOfDay = new Date(now.setHours(0, 0, 0, 0)).getTime();
        const endOfDay = new Date(new Date(now.setDate(now.getDate() + 1)).setHours(0, 0, 0, 0)).getTime();
        if (table === 'current') {
            return filter(bookings, (l) => {
                const future = l.endAt >= endOfDay;
                const today = startOfDay < l.endAt && endOfDay >= l.endAt;
                const dropInToday =
                    l.productType === ProductType.FLESK_DESK_DROP_IN &&
                    l.endAt === null &&
                    startOfDay <= l.startAt &&
                    endOfDay > l.startAt;
                const cancelled = l.bookingState === 'CANCELLED_REQUEST' || l.bookingState === 'CANCELLED_BOOKING';
                return (future || today || dropInToday) && !cancelled;
            });
        }
        if (table === 'past') {
            return filter(bookings, (l) => {
                const past = l.endAt <= endOfDay;
                const today = startOfDay < l.endAt && endOfDay >= l.endAt;
                const cancelled =
                    l.bookingState === 'CANCELLED_REQUEST' ||
                    l.bookingState === 'CANCELLED_BOOKING' ||
                    l.bookingState === 'DECLINED';
                return past && !today && !cancelled;
            });
        }
        return filter(bookings, (l) => {
            const cancelled =
                l.bookingState === 'CANCELLED_REQUEST' ||
                l.bookingState === 'CANCELLED_BOOKING' ||
                l.bookingState === 'DECLINED';
            return cancelled;
        });
    };

    return (
        <>
            <div className={styles.contentWrapper}>
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <div className={styles.bookings}>
                        <div className={styles.generalTitle}>{t('navigation.bookings')}</div>
                        <CompanyBookingsTab bookings={getFilteredBookings('current')} tableType="current" />
                        <CompanyBookingsTab bookings={getFilteredBookings('past')} tableType="past" />
                        <CompanyBookingsTab bookings={getFilteredBookings('cancelled')} tableType="cancelled" />
                    </div>
                )}
            </div>
        </>
    );
};

export default CompanyBookings;
