import React, { useCallback, useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import { useTranslation } from '@ubique-innovation/react-translations';
import { useAuth } from 'react-oidc-context';

import * as styles from './MySpace.css';
import { LocalWorkspaceImage, TimeSlots, Workspace } from '../../types/Workspace';
import LoadingIndicator from '../../components/LoadingIndicator';
import ResourcesForm from '../../components/workspace/mySpace/ResourcesForm';

const API = process.env.REACT_APP_API_URL || '';

const Resources = ({
    setDirty,
    workspaceId,
}: {
    setDirty: (d: boolean) => void;
    workspaceId: number | undefined;
}): React.ReactElement => {
    const [workspace, setWorkspace] = useState<Workspace>();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const auth = useAuth();
    const [savingError, setSavingError] = useState<string>();
    const [openingHours, setOpeningHours] = useState<TimeSlots>();

    useEffect(() => {
        setLoading(true);
        if (workspaceId === undefined) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/workspaces/${workspaceId}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                // if (response.status === 403 || response.status === 401) {
                //     auth.signoutRedirect();
                // }
                return Promise.reject();
            })
            .then(
                (data) => {
                    setWorkspace(data);
                    setLoading(false);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [workspaceId, auth.user?.access_token, auth]);

    const onSave = useCallback(
        (w: Workspace, localImages?: LocalWorkspaceImage[]) => {
            setLoading(true);
            if (workspaceId == null) {
                // TODO handle this
                console.error('unable to save: this user has no workspace');
                return;
            }
            const promises: Promise<Response>[] = [];
            if (workspace != null) {
                workspace.imageIdSortKeys.forEach((img) => {
                    const idx = findIndex(w.imageIdSortKeys, (i) => i.id === img.id);
                    if (idx === -1) {
                        promises.push(
                            fetch(`${API}/v1/admin/workspaces/${workspaceId}/image/${img.id}`, {
                                method: 'DELETE',
                                headers: {
                                    Authorization: `Bearer ${auth.user?.access_token}`,
                                },
                            }),
                        );
                    }
                });
            }
            if (localImages != null) {
                localImages.forEach((img) => {
                    promises.push(
                        img.file.arrayBuffer().then((buffer) => {
                            return fetch(`${API}/v1/admin/workspaces/${workspaceId}/image/add?sortKey=${img.sortKey}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': img.file.type,
                                    Authorization: `Bearer ${auth.user?.access_token}`,
                                },
                                body: new Uint8ClampedArray(buffer),
                            });
                        }),
                    );
                });
            }
            // only update record after updating images
            Promise.all(promises)
                .catch(() => setSavingError(t('messages.error.imageUploadError')))
                .finally(() => {
                    fetch(`${API}/v1/admin/workspaces/${workspaceId}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${auth.user?.access_token}`,
                        },
                        body: JSON.stringify(w),
                    })
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            }
                            return Promise.reject();
                        })
                        .then((data) => {
                            setWorkspace(data);
                            setLoading(false);
                            setDirty(false);
                            setSavingError(undefined);
                        })
                        .catch(() => {
                            setSavingError(t('messages.error.errorWhileSaving'));
                            setLoading(false);
                        });
                });
        },
        [workspaceId, auth.user?.access_token, workspace, setDirty, t],
    );

    useEffect(() => {
        setLoading(true);
        if (workspaceId === undefined) {
            // TODO handle this
            // console.error('this user has no workspace');
            return;
        }
        fetch(`${API}/v1/admin/workspaces/${workspaceId}/openinghours`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject();
            })
            .then(
                (data) => {
                    setOpeningHours(data);
                },
                () => console.error("couldn't fetch record"), // TODO error handling
            );
    }, [workspaceId, auth.user?.access_token, auth]);

    return (
        <>
            <div className={styles.formDiv}>
                {loading || workspace == null || openingHours == null ? (
                    <LoadingIndicator />
                ) : (
                    <ResourcesForm
                        workspace={workspace}
                        onSave={onSave}
                        setDirty={setDirty}
                        savingError={savingError}
                    />
                )}
            </div>
        </>
    );
};

export default Resources;
