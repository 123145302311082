export interface Localization {
    subtitle: string | null;
    teaser: string;
    entryInfo: string;
    checkedInInfo: string;
}

export interface Product {
    type: string;
    price: number | string;
}

export interface WorkspaceImage {
    id: number | string;
    sortKey: number;
    thumbnailUrl: string;
    hiresUrl: string;
}

export interface TimeSlots {
    mon1From: string | null;
    mon1To: string | null;
    mon2From: string | null;
    mon2To: string | null;
    tue1From: string | null;
    tue1To: string | null;
    tue2From: string | null;
    tue2To: string | null;
    wed1From: string | null;
    wed1To: string | null;
    wed2From: string | null;
    wed2To: string | null;
    thu1From: string | null;
    thu1To: string | null;
    thu2From: string | null;
    thu2To: string | null;
    fri1From: string | null;
    fri1To: string | null;
    fri2From: string | null;
    fri2To: string | null;
    sat1From: string | null;
    sat1To: string | null;
    sat2From: string | null;
    sat2To: string | null;
    sun1From: string | null;
    sun1To: string | null;
    sun2From: string | null;
    sun2To: string | null;
}

export interface LocalWorkspaceImage extends WorkspaceImage {
    file: File;
}

export const isLocalWorkspaceImage = (a: WorkspaceImage | LocalWorkspaceImage): a is LocalWorkspaceImage => 'file' in a;

export enum ProductType {
    FLESK_DESK = 'FLESK_DESK',
    FLESK_DESK_DROP_IN = 'FLESK_DESK_DROP_IN',
    FLESK_POD = 'FLESK_POD',
    FLESK_ROOM_SMALL = 'FLESK_ROOM_SMALL',
    FLESK_ROOM_MEDIUM = 'FLESK_ROOM_MEDIUM',
    FLESK_ROOM_LARGE = 'FLESK_ROOM_LARGE',
}

export type TableType = 'current' | 'past' | 'cancelled';

export enum Locale {
    DE = 'de',
    // IT = 'it',
    FR = 'fr',
    EN = 'en',
}

export interface Workspace {
    workspaceId: number;
    title: string;
    address: string;
    city: string;
    primaryLang: Locale;
    bookingMail: string;
    wlanSsid: string;
    wlanPassword: string;
    beneficiaryName: string;
    beneficiaryIban: string | null;
    deskHalfDayPassesSupported: boolean;
    published: boolean;
    mwstPflichtig: boolean;
    mwstNumber: string | null;
    localizations: {
        [l in Locale]?: Localization;
    };
    openingHours: TimeSlots;
    products: Product[];
    imageIdSortKeys: WorkspaceImage[];
}

export interface Workspaces {
    workspaceId: number;
    title: string;
    address: string;
    thumbnailUrl: string;
}

export enum AddressCountryShort {
    CH = 'CH',
    LI = 'LI',
}

export interface Beneficiary {
    beneficiaryBusinessName: string;
    beneficiaryPersonFirstName: string;
    beneficiaryPersonLastName: string;
    beneficiaryMail: string | null;
    beneficiaryIban: string;
    mwstNumber: string | null;
    mwstPflichtig: boolean;
    street: string;
    postCode: string;
    city: string;
    country: AddressCountryShort;
}

export interface BookingsInfo {
    bookingMail: string | null;
}

export interface InvoiceRecipient {
    businessName: string;
    personFirstName: string;
    personLastName: string;
    mail: string | null;
    street: string;
    postCode: string;
    city: string;
    country: AddressCountryShort;
}

export interface Contact {
    correspondenceLanguage: Locale;
    firstName: string;
    lastName: string;
    mail: string | null;
}
